import React from 'react';
import DashboardLayout from '../layouts';
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { API_URL } from '../network/endpoints';
import { getCookie } from 'cookies-next';
import secrets from '../constant/secrets';
import { useNavigate } from 'react-router-dom';

const AddNewInventory = () => {
  const navigate = useNavigate();
  const token = getCookie(secrets.DISTRIBUTOR_ID);
  const { values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      product_name: '',
      manufacturer: '',
      product_type: 'Generic',
      description: '',
    },
    onSubmit: async (values) => {
      console.log('values', values);
      try {
        const { data } = await axios.post(
          `${API_URL}/request_product`,
          values,
          {
            headers: {
             token: token,
            },
          }
        );
        console.log('data', data);
        localStorage.setItem('distributordetails',data)
        alert('New Inventory Added');
        navigate('/distributor/dashboard');
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  return (
    <DashboardLayout>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '@media screen and (max-width: 768px)': {
            mt: 4,
          },
        }}
      >
        <h5 className="card-header float-start">Add New Inventory</h5>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 2,
                '@media screen and (max-width: 768px)': {
                  gridTemplateColumns: 'repeat(2, 1fr)',
                },
                '@media screen and (max-width: 425px)': {
                  gridTemplateColumns: 'repeat(1, 1fr)',
                },
              }}
            >
              <TextField
                id="outlined-textarea"
                label="Product Name"
                placeholder="Product Name"
                name="product_name"
                value={values.product_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                id="outlined-textarea"
                label="Manufacturer"
                placeholder="Mft Name"
                name="manufacturer"
                value={values.manufacturer}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  Product type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.product_type}
                  name="product_type"
                  label="Product type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {product_types.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(1, 1fr)',
                gap: 2,
              }}
            >
              <TextField
                id="outlined-textarea"
                label="Description"
                placeholder="Mft description"
                multiline
                minRows={4}
                name="description"
                value={values.description}
                onChange={handleChange}
              />
            </Box>

            <Box>
              <button 
              // type="submit" 
              variant="text" 
              className="btn"
              style={{backgroundColor:"#6EAFAB",color:"white",borderRadius:"8px"}}>
                Add New Inventory
              </button>
            </Box>
          </Box>
        </form>
      </Card>
    </DashboardLayout>
  );
};

export default AddNewInventory;

const product_types = [
  {
    name: 'Generic',
  },
  {
    name: 'OTC',
  },
  {
    name: 'Branded',
  },
  {
    name: 'Surgerical',
  },
];
