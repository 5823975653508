import React from 'react';
import axios from 'axios';
import toastr from 'toastr';
import DashboardLayout from '../layouts';
import { getCookie } from 'cookies-next';
import secrets from '../constant/secrets';
import { useState, useEffect } from 'react';
import UpdateInventory from './UpdateInventory';
import { API_URL } from '../network/endpoints';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { fetchWithToken } from '../network';
import Distributordetails from '../pages/Distributordetails';
import { useParams } from 'react-router-dom';

const apiUrl = `${API_URL}`;

const Details = () => {
  var tok = JSON.parse(
    String(localStorage.getItem('distributor_details'))
  );
  const { id } = useParams(); 
  // console.log("DETAILS",id);
  // const token = getCookie(secrets.DISTRIBUTOR_ID);
  const token = getCookie(secrets.DISTRIBUTOR_ID);
  const [distributerId1, setDistributerID1] = useState(tok?.data?._id);

  const [distributorList, setDistributorList] = useState('');
  const [data, setData] = useState({});
  const [Data, setDatas] = useState('')
  const [distributorDetailsArray, setDistributorDetailsArray] = useState('');
  useEffect(() => {
    // window.location.reload();
    var activeRoutes = window.location.pathname.split('/');
    var id = activeRoutes[activeRoutes.length - 1];

    var distributerDetails = JSON.parse(
      String(localStorage.getItem('distributor_details'))
    );
    var distributerID = distributerDetails.data._id;
    // console.log('tet', distributerID);
    setDistributerID1(distributerID);


    fetchData();

    distributorProduct(id);
  }, [id]);

  
  const fetchData = async (_id) => {
    try {
      const response = await axios.get(`${API_URL}/my_inventory`, {
        headers: { token: localStorage.getItem("disToken") },
      });
      console.log("response",response.data);
      
      // const newdataid = localStorage.getItem('props');
      
      // Use filter to include only items that have newdataid in their _id array
      const fetchedData = response.data.product.filter((item) => item._id == id);
      // console.log("IDs", _id);
      // console.log("IDs", id);
      // console.log("FRTCHDATA",fetchedData);
      setDatas(fetchedData);
   
      
      console.log("Fetched>>>>>>>>>>>>>>>>>>>>>>Data", Data);
    } catch (error) {
      console.error(error);
    }
  };

  async function distributorProduct(id) {
    try {
      const response = await axios.get(`${API_URL}/distributor_get_product`, {
        headers: {
          token: token,
        },
      });
  
      if (response.data.status === true) {
        var newdata = response.data.product.filter((f) => f._id === id)[0];
  
        setData(newdata);
  
        var distributorDetails = newdata?.distributors?.filter(
          (f) => f.distributorId == distributerId1
        )[0];
  
        setDistributorList(newdata);
        localStorage.setItem("newid", distributorList?._id);
        setDistributorDetailsArray(distributorDetails);
      } else {
        toastr.error(response?.data?.message);
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  
    console.log("?????????????????distributorList._id??????????????????????");
  }
  

  return (
    <DashboardLayout>
      <div className="">
      <h3 className="mt-3">Update The Inventory </h3>
        <Box
          sx={{
            padding: '20px',
            border: '1px solid #f1f1f1',
            borderRadius: '5px',
          }}
        >
          <div className="medicine-details">
          {/* {data && data?.length <= 0 && ( */}
                   
              <div>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                  }}
                >
             
                  <ListItem
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        paddingLeft: '0px',
                      }}
                      primary="Product Name: "
                      secondary={

                        (data?.name || '')
                      }
                    />
                  </ListItem>
                  {/* <ListItem
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                      primary="Distributor Name : "
                      secondary={distributorList?.subtitle}
                    />
                  </ListItem> */}
                  <ListItem
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                      primary="MKt:"
                      secondary={
                        (data?.sub_title)
                      }
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                      primary="Description"
                      secondary={(data?.description)}
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                      primary="Current price:"
                      secondary={Data[0]?.price}

                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                      primary="Current stoke:"
                      secondary={Data[0]?.stock}
                    />
                  </ListItem>
                </List>
              </div>
            {/* )} */}
          </div>

          {/* <div className='offers-div'>
						<Typography variant='h6' mt={2} color='#960018'>
							Offers Available
						</Typography>
						<Offer />
						<Offer />
						<Offer />
					</div>
				</div> */}
          {/* {props.product && ( */}
          {distributorList && (
            <div>
              <UpdateInventory id={distributorList._id} />
            </div>
          )}

          {/* )} */}

          <div>
            {/* <Link
                                      className="dropdown-item"
                                      // to="/distributordetails"
                                      to={"/updateinv/"+distributorList._id}
                                    >
                                      {" "}
                                     Edit Details
                                    </Link> */}

            {/* <Button onClick={() => {
    window.location.href='/updateinv'
  }}>Add Price</Button>			 */}
          </div>
        </Box>
      </div>
    </DashboardLayout>
  );
};

export default Details;
