import React from 'react';
// import Sidebar2 from './sidebar2';
import { useState } from 'react';
import axios from 'axios';
import DashboardLayout from '../layouts';
import { Box, Button } from '@mui/material';
import { API_URL } from '../network/endpoints';
import { useNavigate } from 'react-router-dom';
const apiUrl = `${API_URL}/bulk_update`;

export default function Addbul() {
  const navigate = useNavigate();
  const [bul, setBul] = useState(null);
  console.log(bul);

  // let name, value;
  function handle(e) {
    console.log('file', e.target.files[0]);

    setBul(e.target.files[0]);
  }

  async function submitFunction() {
    const formData = new FormData();
    if (bul) {
      formData.append('file', bul);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('disToken'),
        },
      });

      console.log(response);

      if (response.data.status) {
        alert(response.data.message);
        navigate('/my-inventory');
      } else {
        console.log('file is required');
      }
    } else {
      alert('File is required.');
    }
  }

  const handledownload = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/get_my_inventory`,
        {
          headers: {
            token: localStorage.getItem('disToken'),
          },
          responseType: 'arraybuffer', 
        }
      );
  
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'MyInventory.xlsx';
  
        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();
  
        // Remove the link from the document
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlecancle = async () => {
    navigate('/my-inventory');
  }
  
  return (
    <>
      <DashboardLayout>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Upload csv file</h5>
            <button  
            variant="text" 
            className='btn'
            onClick={handledownload}
            style={{backgroundColor:"#6EAFAB",fontWeight:"700",color:"white",borderRadius:"8px"}}
            >
                Download inventory
            </button>
          </div>
          <hr className="my-0" />
          <div className="card-body">
            <form id="formAccountSettings" method="post">
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label
                    className="form-label float-start"
                    for="csvFile"
                  ></label>
                  <div className="input-group input-group-merge">
                    <input
                      className="form-control"
                      type="file"
                      id="csvFile"
                      name="csvFile"
                      placeholder="enter pharmacist name"
                      onChange={handle}
                      accept='.xml, .csv'
                    />
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="">
                    <button className="btn btn-primary">
                      <a
                        href="https://www.google.co.in/"
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        {' '}
                        Download sample file
                      </a>
                    </button>
                  </div>
                </div> */}
              </div>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem',
                }}
              >
                <Button
                  variant="text"
                  onClick={submitFunction}
                  className="btn"
                  style={{backgroundColor:"#6EAFAB",color:"white",borderRadius:"8px",width:"15%"}}
                >
                  Save
                </Button>
                <Button 
                variant="text" 
                type="reset"
                className="btn"
                style={{backgroundColor:"#DC143C",color:"white",borderRadius:"8px",width:"15%"}}
                onClick={handlecancle}>
                  Cancel
                </Button>
                {/* <button
                  className="btn btn-primary me-2"
                  onClick={submitFunction}
                >
                  Save
                </button>
                <button type="reset" className="btn btn-outline-secondary">
                  Cancel
                </button> */}
              </Box>
            </form>
          </div>
        </Box>
      </DashboardLayout>
    </>
  );
}
