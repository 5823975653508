import './App.css';
import React from 'react';
import AppRoutes from './routes';
// import Ham from "./Ham/Ham";

function App() {
  // const isLoggedIn = useAuth();
  // const user = await getUser();
  // if (!user) {
  //   return redirect("/login");
  // }
  // return null;

  return <AppRoutes />;
}

export default App;
