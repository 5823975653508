import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import '../../src/App.css';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Toolbar from '@mui/material/Toolbar';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { ListSubheader, useMediaQuery } from '@mui/material';

const drawerWidth = 280;

function ResponsiveDrawer(props) {
  const media = useMediaQuery('(max-width:600px)');

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = () => {
    localStorage.removeItem('disToken');
    localStorage.removeItem('id');
    navigate('/distributor/login');
    // window.location.reload();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar style={{ backgroundColor: 'white' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            style={{ color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}

          <div className="app-brand demo logoCss">
            <Link to="/distributor/dashboard" className="app-brand-link">
              <img
                src="../assets/img/logo2.png"
                alt="logo"
                style={{ height: '90px', width: '175px' }}
              />
            </Link>
          </div>

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            style={{ position: 'absolute', right: '20px' }}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Toolbar>
        {/* Avatar Menu */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {media ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            <ListItems />
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            <ListItems />
          </Drawer>
        )}
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
          suscipit odit debitis veritatis ea quam distinctio repellat laboriosam
          vero aliquam nulla praesentium quasi voluptates, id minima doloremque
          provident at sint.
        </p>
      </Box>
      {/* <Box
        component="main"
        sx={{
          p: 3,
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
      </Box> */}
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;

const ListItems = () => {
  return (
    <div>
      {/* <Toolbar /> */}
      <div className=" ">
        <Link to="/distributor/dashboard" className="app-brand-link">
          <img
            src="../assets/img/logo2.png"
            alt="logo"
            style={{ height: '90px', width: '175px' }}
          />
        </Link>
      </div>
      <Divider />
      <List>
        {[
          { data: 'Dashboard', link: '/distributor/dashboard' },
          { data: 'Orders', link: '/orderlist' },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={(e) => {
                e.preventDefault();
                // handleNavigate(text.link);
              }}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text.data} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <p className="sidebarParagarph">Inventory Section</p>
      <CollapseListComponent />
      <List>
        {[
          { data: 'My Inventory', link: '/my-inventory' },
          { data: 'Update Bulk', link: '/bulkuploadinv' },
          {
            data: 'List New products To Inventory',
            link: '/list-new-inventory',
          },
          { data: 'Request To Add New', link: '/add-new-inventory' },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={(e) => {
                e.preventDefault();
                // handleNavigate(text.link);
              }}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text.data} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          { data: 'Payments', link: '/Payouttable' },
          { data: 'Offers', link: '/offer' },
          { data: 'Returns', link: '/distreturn' },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={(e) => {
                e.preventDefault();
                // handleNavigate(text.link);
              }}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text.data} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <p className="sidebarParagarph">Settings Section</p>
      <List>
        {[
          { data: 'Websites Banner', link: '/Banner' },
          { data: 'App Banner', link: '/Banner' },
          { data: 'Profile Settings', link: '/EditBanner' },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={(e) => {
                e.preventDefault();
                // handleNavigate(text.link);
              }}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text.data} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const CollapseListComponent = ({ subheader, list, onClick }) => {
  const isList = list?.length > 0;
  const [show, setShow] = React.useState(true);

  const toggleList = React.useCallback(() => {
    if (isList) {
      setShow(!show);
    } else {
      onClick && onClick();
    }
  }, [isList, onClick, show]);
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {subheader}
        </ListSubheader>
      }
    >
      {/* List */}
      <ListItemButton onClick={toggleList}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Hello" />
        {isList && (show ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {/* Nested List */}
      {isList && (
        <Collapse in={show} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {list.map((item, index) => (
              <ListItemButton sx={{ pl: 4 }} key={index}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </List>
  );
};

const linksArray = [
  { data: 'Dashboard', link: '/distributor/dashboard' },
  { data: 'Orders', link: '/orderlist' },
  { data: 'My Inventory', link: '/my-inventory' },
  { data: 'Update Bulk', link: '/bulkuploadinv' },
  { data: 'List New products To Inventory', link: '/list-new-inventory' },
  { data: 'Request To Add New', link: '/add-new-inventory' },
  { data: 'Payments', link: '/Payouttable' },
  { data: 'Offers', link: '/offer' },
  { data: 'Returns', link: '/distreturn' },
  { data: 'Websites Banner', link: '/Banner' },
  { data: 'App Banner', link: '/Banner' },
  { data: 'Profile Settings', link: '/EditBanner' },
];
