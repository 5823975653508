import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import { API_URL } from '../network/endpoints';

import axios from 'axios';

export default function MainDashbord() {

  const disdata=localStorage.getItem('distributor_details');
  const distributordata = JSON.parse(disdata)
  console.log(distributordata.data);
 
  return (
    <Card
      sx={{
        display: 'flex',
      }}
    >
      <div className="col-sm-7">
        <div className="card-body">
          <h5 className="card-title text-primary">Hello {distributordata.data.firstname}! 🎉</h5>
          <p className="mb-4">
          Your business name is {distributordata?.data?.businessname}. 
            Check your new badge in your profile.
          </p>

          <Link to="javascript:;" className="btn btn-sm btn-outline-primary">
            View Badges
          </Link>
        </div>
      </div>
      <div className="col-sm-5 text-center text-sm-left">
        <div className="card-body pb-0 px-0 px-md-4">
          <img
            src="../assets/img/illustrations/man-with-laptop-light.png"
            height="140"
            alt="View Badge User"
            data-app-dark-img="illustrations/man-with-laptop-dark.png"
            data-app-light-img="illustrations/man-with-laptop-light.png"
          />
        </div>
      </div>
    </Card>
  );
}
