import React, { useEffect } from 'react';
import toastr from 'toastr';
import axios from 'axios';
import MainDashbord from './MainDashbord';
import { useState } from 'react';
// import Dropdown from "react-bootstrap/Dropdown";
import { API_URL } from '../network/endpoints';
import DashboardLayout from '../layouts';
import { getCookie } from 'cookies-next';
import secrets from '../constant/secrets';
const apiUrl = `${API_URL}/distributor_profile`;

export default function Dashbord() {
  const [activeMenu, setactiveMenu] = useState('dashbord');
  const [productId, setproductId] = useState('');
  const [productName, setproductName] = useState('dashbord');
  const token = getCookie(secrets.DISTRIBUTOR_ID);

  useEffect(() => {
    distributorProfile();
  }, []);

  async function distributorProfile() {
    const authaxios = axios.create({
      baseURL: apiUrl,
      headers: {
        token: token,
      },
    });

    await authaxios
      .get()
      .then((res) => {
        console.log(res, 'res');
        if (res.data.status === true) {
          console.log(res, 'respone true');
          const serializedData = JSON.stringify(res.data);
          localStorage.setItem("getdetails", serializedData);
          console.log("Data stored in localStorage:", serializedData);
        } else {
          toastr.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      });
  }

  const sideMenuClick = (selected, id = null, name = null) => {
    setproductName(name);
    setproductId(id);
    console.log(id, 'id', name, 'name', selected, 'selected');
    window.history.pushState(null, '', selected);
    setactiveMenu(selected);
  };
  return (
    <>
      <DashboardLayout>
        <MainDashbord />
      </DashboardLayout>
    </>
  );
}
