import React from "react";
import DashboardLayout from '../layouts';
import Navbar from "./Navbar";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { format } from "date-fns";
import { Card } from "react-bootstrap";

const OffersList = () => {
  const [offers, setOffers] = useState("");

  useEffect(() => {
    getAllOffers();
  }, []);

  const getAllOffers = async () => {
    try {
      const res = await axios.get(`https://api.meddaily.in/getoffer`);
      if (res.status === 200) {
        setOffers(res?.data?.data);
      }
    } catch (error) {}
  };

  return (
    <>
      <DashboardLayout>
        <div className="d-flex justify-content-center ">
            <div className="container">
          <Card className="m-0">
            <h5 className="card-header" style={{ textAlign: "center", fontWeight: "500" }}>
              List Of All Offers
            </h5>
              <h5 style={{ textAlign: "center", fontWeight: "500" }}>Offer Table</h5>
              <div className="table-responsive">
                <table className="table"  style={{ textAlign: "center"}}>
                  <thead>
                    <tr >
                      <th>Distributor Name</th>
                      <th>Type</th>
                      <th>Product</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offers && offers?.length > 0 && offers?.map((item) => (
                      <tr key={item._id}>
                        <td>{item?.distributors?.firstname + " " + item?.distributors?.lastname}</td>
                        <td>{item?.type}</td>
                        <td>{item?.products?.title}</td>
                        <td>{format(new Date(item?.createdAt), 'dd/MM/yyyy')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
          </Card>
            </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default OffersList;
