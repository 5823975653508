import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Adddis from './pages/Adddis';
import Addret from './pages/Addret';
import Distributordetails from './pages/Distributordetails';
import Distributordetailsr from './pages/Distributordetailsr';
import Distributorlist from './pages/Distributorlist';
import Distributorrequest from './pages/Distributorrequest';
import Retailerdetails from './pages/Retailerdetails';
import Retailerdetailsr from './pages/Retailerdetailsr';
import Retailerlist from './pages/Retailerlist';
import Retailerrequest from './pages/Retailerrequest';
import Sidebar from './pages/Sidebar';
import Gstinfo from './pages/Gstinfo';
import Producttable from './pages/Producttable';
import Addproduct from './pages/Addproduct';
import Addbul from './pages/Addbul';
import Ordertable from './pages/Ordertable';
import OrderDetails from './pages/OrderDetails';
import UpdateOrder from './pages/UpdateOrder';
import Navbar from './pages/Navbar';
import Updateproduct from './pages/Updateporduct';
import Offers from './pages/Offers';
import Returntable from './pages/Returntable';
import Payouttable from './pages/Payouttable';
import CategoryList from './pages/CategoryList';
import AddNewCategory from './pages/AddNewCat';
import Banner from './pages/Banner';
import EditBanner from './pages/EditBanner';
import AddNewBan from './pages/AddNewBan';
import Inventory from './pages/inventory'
// distributor
import DistLogin from './DistributorLogin/DisLogin';
import OrderList from './DistributorLogin/OrderList';
import Details from './DistributorLogin/ProductDetails';
import MyInventory from './DistributorLogin/MyInventory';
import DistDashboard from './DistributorLogin/DistDashboard';
import BulkUploadInv from './DistributorLogin/BulkUploadInv';
import DisReturnTable from './DistributorLogin/DisReturnTable';
import UpdateInventory from './DistributorLogin/UpdateInventory';
import AddNewInventory from './DistributorLogin/AddNewInventory';
import ListNewProductInv from './DistributorLogin/ListNewProductInv';

import OrderDates from './pages/OrderDates';
import OrderIdFilter from './pages/OrderIdFilter';
import OrderDisId from './pages/OrderDisId';
import OffersList from './pages/OffersList';
import EditCategory from './pages/EditCategory';
import TestPage from './pages/test';
import NotFound from './pages/NotFound';
import { getCookie } from 'cookies-next';
import secrets from './constant/secrets';

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie(secrets.DISTRIBUTOR_ID);
  React.useEffect(() => {
    console.log('location', location.pathname);
    if (
      location.pathname === '/' ||
      location.pathname === '/distributor/dashboard'
    ) {
      if (token) {
        navigate('/distributor/dashboard');
      } else {
        navigate('/distributor/login');
      }
    }
  }, [location.pathname, navigate, token]);

  return (
    <Routes>
      {/* Auth Page */}
      <Route path="*" element={<NotFound />} />
      <Route path="/distributor/login" element={<DistLogin />} />
      <Route exact path="/distributor/dashboard" element={<DistDashboard />} />
      <Route exact path="/test" element={<TestPage />} />
      {/* admin */}
      <Route path="/adddis" element={<Adddis />} />
      <Route path="/addret" element={<Addret />} />
      <Route path="/distributordetails" element={<Distributordetails />} />
      <Route path="/distributordetailsr" element={<Distributordetailsr />} />
      <Route path="/distributorlist" element={<Distributorlist />} />
      <Route path="/distributorrequest" element={<Distributorrequest />} />
      <Route path="/retailerdetails" element={<Retailerdetails />} />
      <Route path="/retailerdetailsr" element={<Retailerdetailsr />} />
      <Route path="/retailerlist" element={<Retailerlist />} />
      <Route path="/retailerrequest" element={<Retailerrequest />} />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/gstinfo" element={<Gstinfo />} />
      <Route path="/producttable" element={<Producttable />} />
      <Route path="/addproduct" element={<Addproduct />} />
      <Route path="/addbul" element={<Addbul />} />
      <Route path="/ordertable" element={<Ordertable />} />
      <Route path="/orderdetails/:id" element={<OrderDetails />} />
      <Route path="/updateorder/:id" element={<UpdateOrder />} />
      <Route path="/navbar" element={<Navbar />} />
      <Route path="/updateproduct" element={<Updateproduct />} />
      <Route path="/offer" element={<Offers />} />
      <Route path="/returntable" element={<Returntable />} />
      <Route path="/categorylist" element={<CategoryList />} />
      <Route path="/addnewcategory" element={<AddNewCategory />} />
      <Route path="/payouttable" element={<Payouttable />} />
      <Route path="/banner" element={<Banner />} />
      <Route path="/editbanner" element={<EditBanner />} />
      <Route path="/addnewban" element={<AddNewBan />} />
      <Route path="/orderdates" element={<OrderDates />} />
      <Route path="/orderidfilter" element={<OrderIdFilter />} />
      <Route path="/orderdisid" element={<OrderDisId />} />
      <Route path="/offerslist" element={<OffersList />} />
      <Route path="/editcategory" element={<EditCategory />} />
      {/* distributor */}
      <Route path="/orderlist" element={<OrderList />} />
      <Route path="/distreturn" element={<DisReturnTable />} />
      <Route path="/my-inventory" element={<MyInventory />} />
      <Route path="/add-new-inventory" element={<AddNewInventory />} />
      <Route path="/bulkuploadinv" element={<BulkUploadInv />} />
      <Route path="/list-new-inventory" element={<ListNewProductInv />} />
      <Route path="/updateinv/:id" element={<UpdateInventory />} />
      <Route path="/productdetails/:id" element={<Details />} />
      <Route path="/inventory" element={<Inventory />} />
      {/* End */}
    </Routes>
  );
};
export default AppRoutes;
