import React, { useEffect, useState } from "react";
import axios from "axios";
import toastr from "toastr";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import DashboardLayout from "../layouts";
import { Card } from "@mui/material";
import { getCookie } from "cookies-next";
import secrets from "../constant/secrets";
import { API_URL } from "../network/endpoints";

const style = {
  position: "absolute",
  height: "500px",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DisReturnTable() {
  const token = getCookie(secrets.DISTRIBUTOR_ID);
  const [returnList, setReturnList] = useState([]);
  const [returnDetails, setReturnDetails] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  console.log(returnList, returnDetails, "returnDetails");
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getAllReturns();
  }, [token, fromDate, toDate]);

  const handleFromDateChange = (event) => {
    console.log(">>>>", event.target.value);
    const currentTime = new Date();
    const hours = currentTime.getUTCHours().toString().padStart(2, "0");
    const minutes = currentTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getUTCSeconds().toString().padStart(2, "0");
    const milliseconds = currentTime
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0");
    const timeString = `${hours}:${minutes}:${seconds}.${milliseconds}`;
    const dateTimeString = `${event.target.value}T${timeString}Z`;
    setFromDate(dateTimeString);
    console.log(fromDate);
  };

  const handleToDateChange = (event) => {
    const currentTime = new Date();
    const hours = currentTime.getUTCHours().toString().padStart(2, "0");
    const minutes = currentTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getUTCSeconds().toString().padStart(2, "0");
    const milliseconds = currentTime
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0");
    const timeString = `${hours}:${minutes}:${seconds}.${milliseconds}`;
    const dateTimeString = `${event.target.value}T${timeString}Z`;
    setToDate(dateTimeString);
    console.log(toDate);
  };

  async function getAllReturns() {
    console.log(token, "disTokendisToken");
    try {
      const response = await axios.get(
        `${API_URL}/get_all_return?from=${fromDate}&to=${toDate}`,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            token,
          },
        }
      );

      if (response.status === 200) {
        setReturnList(response?.data?.data);
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  }
  const handleReject = async (data) => {
    try {
      const response = await axios.post(
        `${API_URL}/return_order_reject`,
        {
          order_id: data.order_id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            token,
          },
        }
      );

      if (response.status === 200) {
        console.log("tiempo", response.data.message);
        if (response.data.message == "Already Rejected") {
          Swal.fire({
            title: "Error!",
            text: "Return already rejected",
            confirmButtonText: "Okay",
          });
        } else {
          // setReturnList(response?.data?.data);
          Swal.fire("Return has been rejected!").then(() => {
            window.location.reload();
          });
        }
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  async function acceptReturn(data) {
    // console.log(disToken,'disTokendisToken')
    try {
      const response = await axios.post(
        `${API_URL}/return_order_accept`,
        {
          order_id: data.order_id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            token,
          },
        }
      );

      if (response.status === 200) {
        console.log("tiempo", response.data.message);
        if (response.data.message == "Already accepted") {
          Swal.fire({
            title: "Error!",
            text: "Return already accepted",

            confirmButtonText: "Okay",
          });
        } else {
          // setReturnList(response?.data?.data);
          Swal.fire("Success", "Return has been accepted!", "success").then(
            () => {
              // window.location.reload()
            }
          );
        }
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  }
  const [order_id, setorderId] = useState(null);

  const handleOpen = (order_id) => {
    console.log(order_id);
    setorderId(order_id);
    setOpen(true);
    // setReturnDetails(data);
  };
  useEffect(() => {
    const fetchData = async () => {
      // console.log(order_id);
      const config2 = {
        headers: {
          token,
        },
      };
      try {
        const response = await axios.get(
          `${API_URL}/order_details?order_id=${order_id}`,
          config2
        );

        console.log(response?.data);
        if (response.status === 200) {
          // console.log("NEW DATA", response.data.status.data);
          setReturnDetails(response?.data?.data);
        }
      } catch (err) {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    };

    fetchData();
  }, [order_id]);

  const columns = [
    {
      label: "Order ID",
      field: "order_id",
      sort: "asc",
    },
    {
      label: "Retailer NAME",
      field: "retailer_name",
      sort: "asc",
    },
    {
      label: "Return Amount",
      field: "price",
      sort: "asc",
    },
    {
      label: "Quantity",
      field: "return_quantity",
      sort: "asc",
    },
    {
      label: "View More",
      field: "view_more",
    },
  ];

  // Define rows for the table
  const rows = returnList?.map((val, i) => {
    // Map over the products array to get an array of prices and return quantities
    const productDetails = val.products.map((product, index) => ({
      price: product.price || 0,
      return_quantity: product.return_quantity || 0,
      quantity: product.quantity || 0,
    }));

    return {
      order_id: (
        <p
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          }}
        >
          {val.order_id || "NA"}
        </p>
      ),
      retailer_name: val.retailer_name || "NA",
      // Use the map result for prices and return quantities
      price: val.delivery_fee === true ? val.price - 118 : val.price,
      return_quantity: productDetails.reduce((accumulator, detail) => {
        return accumulator + detail.return_quantity;
      }, 0),
      view_more: (
        <Link
          onClick={(e) => {
            e.preventDefault();
            handleOpen(val?.order_id);
          }}
          variant="text"
          className=" btn"
          style={{
            backgroundColor: "#6EAFAB",
            color: "white",
            borderRadius: "8px",
          }}
        >
          View Details
        </Link>
      ),
    };
  });
  // const rows = filteredOrders?.map((val, i) => {
  //   const productDetails = val.products.map((product, index) => ({
  //     price: product.price || 0,
  //     return_quantity: product.return_quantity || 0,
  //     quantity: product.quantity || 0,
  //   }));

  //   return {
  //     order_id: (
  //       <p
  //         style={{
  //           overflow: "hidden",
  //           whiteSpace: "nowrap",
  //           textOverflow: "ellipsis",
  //           maxWidth: "150px",
  //         }}
  //       >
  //         {val.order_id || "NA"}
  //       </p>
  //     ),
  //     retailer_name: val.retailer_name || "NA",
  //     price: val.delivery_fee === true ? val.price - 118 : val.price,
  //     return_quantity: productDetails.reduce((accumulator, detail) => {
  //       return accumulator + detail.return_quantity;
  //     }, 0),
  //     view_more: (
  //       <Link
  //         onClick={(e) => {
  //           e.preventDefault();
  //           handleOpen(val);
  //         }}
  //         variant="text"
  //         className=" btn"
  //         style={{
  //           backgroundColor: "#6EAFAB",
  //           color: "white",
  //           borderRadius: "8px",
  //         }}
  //       >
  //         View Details
  //       </Link>
  //     ),
  //   };
  // });
  function paymentType(code) {
    if (code == 1) {
      return "COD";
    } else if (code == 3) {
      return "On Credit";
    } else if (code == 2) {
      return "PrePaid";
    } else if (code == 0) {
      return "COD";
    }
  }

  return (
    <DashboardLayout>
      <Card>
        <h5 className="card-header">Return Table</h5>
        <div className="card-body">
          <div className="row">
            <label
              htmlFor="fromDate"
              className="col-sm-2 col-form-label text-end"
            >
              From Date:
            </label>
            <div className="col-sm-4">
              <input
                type="date"
                id="fromDate"
                className="form-control"
                onChange={handleFromDateChange}
                value={fromDate.split("T")[0]}
              />
            </div>
            <label
              htmlFor="toDate"
              className="col-sm-2 col-form-label text-end"
            >
              To Date:
            </label>
            <div className="col-sm-4">
              <input
                type="date"
                id="toDate"
                className="form-control"
                onChange={handleToDateChange}
                value={toDate.split("T")[0]}
              />
            </div>
          </div>
        </div>
        <div className="table-responsive text-nowrap">
          <MDBDataTable
            striped
            bordered
            hover
            data={{ columns, rows }}
            responsive
            noBottomColumns={true}
          />
        </div>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Product Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Order Id: {returnDetails?.order_id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Expiry Date: {returnDetails?.products[0]?.exp_date}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Price :{" "}
            {returnDetails?.delivery_fee === true
              ? returnDetails?.price - 118
              : returnDetails?.price}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            marginTop={"15px"}
          >
            Distributer and Retailer Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Distributer Name : {returnDetails?.distributor_name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Retailer Name : {returnDetails?.retailer_name}
          </Typography>
          <hr />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            marginTop={"15px"}
          >
            Payment Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Payment Status : {returnDetails?.payment_status}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Payment Type : {paymentType(returnDetails?.payment_type)}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Order Status:{" "}
            {returnDetails?.order_status == 5 ? "Order Return" : ""}
            {returnDetails?.order_status == 4 ? "Order Placed" : ""}{" "}
            {returnDetails?.order_status == 1 ? "Order Shipped" : ""}
            {returnDetails?.order_status == 3 ? "Order Delivered" : ""}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            marginTop={"15px"}
          >
            Payment Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Return Reason: {returnDetails?.return_reason}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Return Message: {returnDetails?.return_message}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Return Quantity:{" "}
            {returnDetails?.products?.reduce((accumulator, detail) => {
              return accumulator + detail.return_quantity;
            }, 0)}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Return Status:{" "}
            {returnDetails?.return_status == 1 ? "Not Accepted" : ""}
            {returnDetails?.return_status == 2 ? "Return Accepted" : ""}{" "}
            {returnDetails?.return_status == 3 ? "Delivered" : ""}
          </Typography>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {returnDetails?.return_status == 1 ? (
              <Box>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    acceptReturn(returnDetails);
                    handleClose();
                  }}
                  variant="text"
                  className=" btn"
                  style={{
                    backgroundColor: "#6EAFAB",
                    color: "white",
                    borderRadius: "8px",
                    marginTop: "20px",
                  }}
                >
                  Accept Return
                </button>
              </Box>
            ) : (
              ""
            )}
            {returnDetails?.return_status == 1 ? (
              <Box>
                <button
                  onClick={() => {
                    handleReject(returnDetails);
                    handleClose();
                  }}
                  variant="text"
                  className=" btn"
                  style={{
                    backgroundColor: "#6EAFAB",
                    color: "white",
                    borderRadius: "8px",
                    marginTop: "20px",
                  }}
                >
                  Reject Return
                </button>
              </Box>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}
{
  /* <Modal
open={open}
onClose={handleClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={style}>
  <Typography id="modal-modal-title" variant="h6" component="h2">
    Product Details
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Order Id: {returnDetails?.order_id}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Expiry Date: {returnDetails?.exp_date}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Price : {returnDetails?.price}
  </Typography>
  <Typography id="modal-modal-title" variant="h6" component="h2" marginTop={"15px"}>
      Distributer and Retailer Details
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Distributer Name : {returnDetails?.distributor_name}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Retailer Name : {returnDetails?.retailer_name}
  </Typography>
  <hr />
  <Typography id="modal-modal-title" variant="h6" component="h2" marginTop={"15px"}>
      Payment Details
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Payment Status : {returnDetails?.payment_status}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Payment Type : {returnDetails?.payment_type}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Order Status: {returnDetails?.order_status}
  </Typography>
  <Typography id="modal-modal-title" variant="h6" component="h2" marginTop={"15px"}>
      Payment Details
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Return Reason: {returnDetails?.return_reason}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Return Message: {returnDetails?.return_message}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Return Quantity: {returnDetails?.return_quantity}
  </Typography>
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    Return Status: {returnDetails?.return_status}
  </Typography>
  <Box style={{textAlign:"end"}}>
  <Button
    variant="contained"
    style={{ marginTop: "20px"  }}
    onClick={(e) => {
      e.preventDefault();
      acceptReturn(returnDetails);
      handleClose();
    }}
  >
    Accept Return
  </Button>
  </Box>
  
</Box>
</Modal> */
}
