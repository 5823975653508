import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { ListSubheader } from '@mui/material';

const drawerWidth = 280;

function SideBar({ media, showNavigation, toggleNavigation }) {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      {media ? (
        <Drawer
          variant="temporary"
          open={showNavigation}
          onClose={toggleNavigation}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <ListItems />
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          <ListItems />
        </Drawer>
      )}
    </Box>
  );
}

export default SideBar;

const ListItems = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: (color) => `2px solid ${color.palette.grey[200]}`,
        }}
      >
        <Link to="/distributor/dashboard" className="app-brand-link">
          <img
            alt="logo"
            src={'../assets/img/logo2.png'}
            style={{ height: '80px', width: '100%', objectFit: 'contain' }}
          />
        </Link>
      </Box>
      {list.map((item, index) => {
        return (
          <React.Fragment>
            <CollapseListComponent
              key={index}
              title={item.title}
              subheader={item.subheader}
            >
              {item.children.map(({ name, href }, innerIndex) => (
                <ListItem key={innerIndex} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <Link
                      to={href}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <ListItemText primary={name} />
                    </Link>
                  </ListItemButton>
                </ListItem>
              ))}
            </CollapseListComponent>
            {item.divider && (
              <Divider
                sx={{
                  color: (theme) => theme.palette.grey[50],
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const CollapseListComponent = ({ subheader, title, children }) => {
  const childrenLength = React.Children.toArray(children).length;

  const [show, setShow] = React.useState(true);
  const toggleList = React.useCallback(() => {
    setShow(!show);
  }, [show]);
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
      component="nav"
      aria-labelledby="subheader"
      subheader={
        <ListSubheader
          id="subheader"
          sx={{
            fontSize: '1rem',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {subheader}
        </ListSubheader>
      }
    >
      {/* List */}
      {childrenLength > 1 ? (
        <ListItemButton onClick={toggleList}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={title} />
          {childrenLength > 1 && (show ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      ) : (
        children
      )}

      {/* Nested List */}
      {childrenLength > 1 && (
        <Collapse in={show} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </List>
  );
};

const list = [
  {
    title: 'Dashboard',
    children: [{ name: 'Dashboard', href: '/distributor/dashboard' }],
    divider: true,
    href: '/distributor/dashboard',
  },
  {
    title: 'Orders',
    children: [{ name: 'Orders', href: '/orderlist' }],
    divider: true,
    href: '/orderlist',
  },
  {
    title: 'Inventory Section',
    subheader: 'Inventory',
    children: [
      { name: 'My Inventory', href: '/my-inventory' },
      { name: 'Update Bulk', href: '/bulkuploadinv' },
      { name: 'List New products To Inventory', href: '/list-new-inventory' },
      { name: 'Request To Add New', href: '/add-new-inventory' },
    ],
    divider: true,
  },
  {
    title: 'Payments',
    children: [{ name: 'Payments', href: '/Payouttable' }],
    // divider: true,
    href: '/Payouttable',
  },
  {
    title: 'Offers & Deals',
    children: [
      { name: 'Offers', href: '/offer' },
      { name: 'All Offers', href: '/offerslist' }
    ],
    // divider: true,
    href: '/offer',
  },
  {
    title: 'Returns',
    children: [{ name: 'Returns', href: '/distreturn' }],
    href: '/distreturn',
  },
  // {
  //   title: 'Settings Section',
  //   subheader: 'Settings',
  //   children: [
  //     { name: 'Websites Banner', href: '/Banner' },
  //     { name: 'App Banner', href: '/Banner' },
  //     { name: 'Profile Settings', href: '/EditBanner' },
  //   ],
  // },
];
