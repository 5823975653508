import React from 'react';
import DashboardLayout from '../layouts';
import { Box, Button, Card, CircularProgress } from '@mui/material';
import useSWR from 'swr';
import { fetchWithToken } from '../network';
import { API_URL } from '../network/endpoints';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';  
import { getCookie } from 'cookies-next'; 
import secrets from '../constant/secrets'; 

const MyInventory = () => {
  const token = getCookie(secrets.DISTRIBUTOR_ID);
  const url = `${API_URL}/my_inventory`;
  const { data, isLoading } = useSWR(
    token ? [url, token] : null,
    ([url, token]) =>
      fetchWithToken(url, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          token,
        },
      })
  );

  // Define columns for the table
  const columns = [
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Subtitle',
      field: 'subtitle',
    },
    {
      label: 'Price',
      field: 'price',
    },
    {
      label: 'Stock',
      field: 'stock',
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
    },
  ];

  // Prepare rows data
  const rows = data?.product?.map((item) => ({
    name:(
      <td
      style={{
        padding: '1rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '150px', // Adjust this value as needed
      }}
    >
      {item?.name}
    </td>
    ) ,
    subtitle:(
      <td
      style={{
        padding: '1rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '150px', // Adjust this value as needed
      }}
    >
      {item.subtitle}
    </td>
    ) ,
    price: item?.price,
    stock: item?.stock,
    action: (
      <Link to={`/productdetails/${item._id}`}>
        <button variant="text" className=" btn"  style={{backgroundColor:"#6EAFAB",color:"white",borderRadius:"8px"}} sx={{ fontSize: '12px' }}>
          Update
        </button>
      </Link>
    ),
  }));

  // Data for MDBDataTable
  const dataTableData = {
    columns,
    rows,
  };

  return (
    <DashboardLayout>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h5
          style={{
            padding: '1rem',
          }}
        >
          My Inventory
        </h5>
        <div className="table-responsive text-nowrap">
          {isLoading ? (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderTop: '1px solid #ccc',
                  borderRadius: '5px',
                  height: '200px',
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          ) : (
            <MDBDataTable
              striped
              bordered
              small
              data={dataTableData}
              noBottomColumns={true}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default MyInventory;
