import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Navbar from './Navbar';
// import Sidebar2 from '../DistributorLogin/sidebar2';
import DashboardLayout from '../layouts';
// import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'cookies-next';
import axios from 'axios';
import { API_URL } from '../network/endpoints';
import secrets from '../constant/secrets';

export default function Offer() {

  const navigate = useNavigate();
  const token = getCookie(secrets.DISTRIBUTOR_ID);

  const [offerData, setOfferData] = useState({
    type: 'Bonus Product', // Set the default value
    product_id: '',
    purchase_quantity: null,
    bonus_quantity: null,
  });
  const [productList, setProductList] = useState([]); // to store the product list

  useEffect(() => {
    // Fetch product list from the API
    const fetchProductList = async () => {
      try {
        const { data } = await axios.get(
          `${API_URL}/my_inventory`,
          {
            headers: {
              token: token,
            },
          }
        );
        // console.log("??>>><<<<<<<<<<????", data.product);
        setProductList(data.product); // assuming the API response is an array of products
      } catch (error) {
        console.error('Error while fetching product list:', error);
      }
    };

    fetchProductList();
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOfferData({
      ...offerData,
      [name]: value,
    });
  };

  const handleAddOffer = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_URL}/addoffer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem("disToken") 
        },
        body: JSON.stringify(offerData),
      });
      const responseData = await response.json();

      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", responseData);

      if (responseData.status === true) {
        // API call was successful
        alert('Offer created successfully');
        navigate('/distributor/dashboard');
        // You may want to redirect or perform some other action after a successful API call
      } else {
        // API call failed
        alert('Offer Already Exist');
        navigate('/distributor/dashboard');
        console.error('Offer Already Exist');
      }
    } catch (error) {
      console.error('Error while adding an offer:', error);
    }
  };

  return (
    <DashboardLayout>
      <div className="content-wrapper " style={{ marginTop: "100px", marginLeft: "0px" }}>
        <div className="container " >
          <div className="card ml-0">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-0">ADD OFFER</h5>
            </div>
            <hr className="my-0" />

            <div className="card-body">
              <form id="formAccountSettings" onSubmit={handleAddOffer}>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="offerType" className="form-label float-start">
                      Offer type
                    </label>
                    <select
                      className="form-select"
                      name="type"
                      value={offerData.type}
                      onChange={handleInputChange}
                      aria-readonly
                    >
                      <option value="Bonus Product">Bonus Product</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="productName" className="form-label float-start">
                      Select product
                    </label>
                    <select
                      className="form-select"
                      name="product_id"
                      value={offerData.product_id}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>Select product</option>
                      {productList?.map(product => (
                        <option key={product._id} value={product._id}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="purchase" className="form-label float-start">
                      Purchase
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="purchase_quantity"
                      placeholder="Enter purchase amount"
                      value={offerData.purchase_quantity}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="freeProductCount" className="form-label float-start">
                      Free product count
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="bonus_quantity"
                      placeholder="Enter free product count"
                      value={offerData.bonus_quantity}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div >
                  <button 
                  type="submit" 
                  className="btn"
                  style={{backgroundColor:"#6EAFAB",color:"white",borderRadius:"8px"}}>
                    Add Offer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
