import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import toastr from "toastr";
import Sidebar from "./Sidebar";
import Container from "react-bootstrap/Container";
import { Row, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Navbar from "./Navbar";
import Loading from "../Loading/Loading";
import { Button } from "bootstrap";
import OrderDetails from "./OrderDetails";
import Sidebar2 from "./sidebar2";
import { API_URL } from "../network/endpoints";
import { MDBDataTable } from "mdbreact";
import "toastr/build/toastr.min.css";
import "mdbreact/dist/css/mdb.css";
import DashboardLayout from "../layouts";
import { Box, Card } from "@mui/material";

export default function OrderList() {
  const authToken = localStorage.getItem("disToken");
  const [orderDetails, setOrderDetails] = useState(0);
  const [orders, setOrders] = useState([]);
  console.log(orders, orderDetails, "orders");
  const [reload, setReload] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const disToken = "";
  useEffect(() => {
    // getOrderDetails();
    getOrders();
  }, [reload, fromDate, toDate]);

  const handleFromDateChange = (event) => {
    console.log(">>>>", event.target.value);
    const currentTime = new Date();
    const hours = currentTime.getUTCHours().toString().padStart(2, "0");
    const minutes = currentTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getUTCSeconds().toString().padStart(2, "0");
    const milliseconds = currentTime
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0");
    const timeString = `${hours}:${minutes}:${seconds}.${milliseconds}`;
    const dateTimeString = `${event.target.value}T${timeString}Z`;
    setFromDate(dateTimeString);
  };

  const handleToDateChange = (event) => {
    const currentTime = new Date();
    const hours = currentTime.getUTCHours().toString().padStart(2, "0");
    const minutes = currentTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getUTCSeconds().toString().padStart(2, "0");
    const milliseconds = currentTime
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0");
    const timeString = `${hours}:${minutes}:${seconds}.${milliseconds}`;
    const dateTimeString = `${event.target.value}T${timeString}Z`;
    setToDate(dateTimeString);
  };

  const getOrders = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/distributor_order?from=${fromDate}&to=${toDate}`,
        {
          headers: {
            token: `${authToken}`,
          },
        }
      );

      console.log(response?.data?.data);
      if (response.status === 200) {
        setOrders(response?.data?.data);
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  async function getOrderDetails() {
    // return;
    try {
      const response = await axios.get(`${API_URL}/distributor_get_product`, {
        headers: {
          token: `${disToken}`,
        },
      });

      if (response.status === 200) {
        setOrderDetails(response?.data?.product);
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
      console.log(err);
    }
  }
  const MAX_DESCRIPTION_LENGTH = 50;

  // Define columns for the table
  const data = {
    columns: [
      {
        label: "ORDER ID",
        field: "order_id",
        sort: "asc",
      },
      {
        label: "RETAILER NAME",
        field: "retailer_name",
        sort: "asc",
      },
      {
        label: "PAYMENT TYPE",
        field: "payment_type",
        sort: "asc",
      },
      {
        label: "PRICE",
        field: "price",
        sort: "asc",
      },
      {
        label: "ACTION",
        field: "action",
        sort: "asc",
      },
    ],
    rows: [],
    searchLabel: "Search", // Add this line to enable search
  };

  // Populate the rows data
  // if (orders?.length > 0) {
  //   data.rows = orders.map((o) => ({
  //     order_id: o.order_id,
  //     retailer_name: o.retailer_name,
  //     payment_type:
  //       o?.payment_type === 1
  //         ? "Cash on delivery"
  //         : o?.payment_type === 2
  //         ? "Payment prepaid"
  //         : o?.payment_type === 3
  //         ? "Credit"
  //         : "",
  //     price:
  //       o.delivery_fee === true
  //         ? (o.price - 118).toFixed(2)
  //         : o.price.toFixed(2),
  //     action: (
  //       <button
  //         className="btn"
  //         variant="text"
  //         style={{
  //           backgroundColor: "#6EAFAB",
  //           color: "white",
  //           borderRadius: "8px",
  //         }}
  //         sx={{ fontSize: "12px" }}
  //         onClick={() => setOrderDetails(o._id)}
  //       >
  //         View Details
  //       </button>
  //     ),
  //   }));
  // }
  if (orders?.length > 0) {
    data.rows = orders
      .filter((o) => !o.order_id.includes("RETURN"))
      .map((o) => ({
        order_id: o.order_id,
        retailer_name: o.retailer_name,
        payment_type:
          o?.payment_type === 1
            ? "Cash on delivery"
            : o?.payment_type === 2
            ? "Payment prepaid"
            : o?.payment_type === 3
            ? "Credit"
            : "",
        price: o.delivery_fee ? (o.price - 118).toFixed(2) : o.price.toFixed(2),
        action: (
          <button
            className="btn"
            variant="text"
            style={{
              backgroundColor: "#6EAFAB",
              color: "white",
              borderRadius: "8px",
            }}
            sx={{ fontSize: "12px" }}
            onClick={() => setOrderDetails(o.order_id)}
          >
            View Details
          </button>
        ),
      }));
  }
  return (
    <DashboardLayout>
      {/* <div className="card-body"> */}
      <div className="">
        <div className="">
          <div className="">
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2 className="card-header float-start">Order Table</h2>
              <div className="card-body">
                <div className="row mb-3">
                  <label
                    htmlFor="fromDate"
                    className="col-sm-2 col-form-label text-end"
                  >
                    From Date:
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="date"
                      id="fromDate"
                      className="form-control"
                      onChange={handleFromDateChange}
                      value={fromDate.split("T")[0]}
                    />
                  </div>
                  <label
                    htmlFor="toDate"
                    className="col-sm-2 col-form-label text-end"
                  >
                    To Date:
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="date"
                      id="toDate"
                      className="form-control"
                      onChange={handleToDateChange}
                      value={toDate.split("T")[0]}
                    />
                  </div>
                </div>
              </div>
              <hr className="my-0" />
              <Box
                sx={{
                  p: [1, 3],
                }}
              >
                <div className=" table-responsive text-nowrap">
                  <MDBDataTable
                    striped
                    bordered
                    small
                    data={data}
                    noBottomColumns={true}
                  />
                </div>
                {orderDetails !== 0 && (
                  <OrderDetails
                    handleClose={(flag = false) => {
                      setOrderDetails(0);
                      if (flag) {
                        setReload(!reload);
                      }
                    }}
                    order_id={orderDetails}
                  />
                )}
              </Box>
            </Card>
          </div>
        </div>
      </div>
      {/* </div> */}
    </DashboardLayout>
  );
}
