import React from 'react';
import { Link } from 'react-router-dom';
import { getCookie } from 'cookies-next';
import secrets from '../constant/secrets';
import useSWR from 'swr';
import { fetchWithToken } from '../network';
import DashboardLayout from '../layouts';
import { Box, Button, Card, CircularProgress } from '@mui/material';
import { MDBDataTable } from 'mdbreact';
import { API_URL } from '../network/endpoints';

const ListNewProductinv = () => {
  const token = getCookie(secrets.DISTRIBUTOR_ID);
  const url = `${API_URL}/distributor_get_product`;
  const { data, isLoading, error } = useSWR(
    token ? [url, token] : null,
    ([url, token]) =>
      fetchWithToken(url, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          token,
        },
      })
  );

  // Define columns for the table
  const columns = [
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Business Name',
      field: 'sub_title',
    },
    {
      label: 'Description',
      field: 'description',
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
    },
  ];

  // Prepare rows data
  const rows = data?.product?.map((item) => ({
    name: item.name,
    sub_title: (
      <td
        style={{
          padding: '1rem',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '150px', // Adjust this value as needed
        }}
      >
        {item.sub_title}
      </td>
    ),
    description: (
      <td
        style={{
          padding: '1rem',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '150px', // Adjust this value as needed
        }}
      >
        {item?.description || 'N/A'}
      </td>
    ),
    action: (
      <Link to={`/productdetails/${item._id}`}>
        <button
          className='btn'
          variant="text"
          sx={{ fontSize: '12px' }}
          style={{ backgroundColor: "#6EAFAB", color: "white", borderRadius: "8px" }}>
          View Details
        </button>
      </Link>
    ),
  }));

  // Data for MDBDataTable
  const dataTableData = {
    columns,
    rows,
  };

  return (
    <DashboardLayout>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h2 className="card-header float-start">New Inventory</h2>
        <hr className="my-0" />
        <Box
          sx={{
            p: [1, 3],
          }}
        >
          <div className="table-responsive text-nowrap" >
            {isLoading ? (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTop: '1px solid #ccc',
                    borderRadius: '5px',
                    height: '200px',
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            ) : (
              <MDBDataTable
                striped
                bordered
                small
                data={dataTableData}
                noBottomColumns={true}
              />
            )}
          </div>
        </Box>
      </Card>
    </DashboardLayout>
  );
};

export default ListNewProductinv;

