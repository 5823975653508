import React, { useEffect, useState } from "react";
import axios from "axios";
import toastr from "toastr";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import config from "../appConfig";
import { MDBDataTable } from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import { API_URL } from '../network/endpoints';


export default function Returntable() {
  const authToken = localStorage.getItem("authToken");

  const [returnList, setReturnList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    if (fromDate && toDate) {
      getAllReturns();
    }
  }, [fromDate, toDate]);

  async function getAllReturns() {
    try {
      const response = await axios.get(
        `${API_URL}/get_return_admin?from=${fromDate}&to=${toDate}`
      );
      if (response.status === 200) {
        console.log(response.data.data);
        setReturnList(response?.data?.data);
      }
    } catch (err) {
      toastr.error(err.response.data.message);
      console.log(err);
    }
  }

  console.log(returnList);

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };


  const columns = [
    {
      label: 'Order ID',
      field: 'order_id',
      sort: 'asc',
    },
    {
      label: 'Vendor Name',
      field: 'name',
      sort: 'asc',
    },
    {
      label: 'Return Amount',
      field: 'price',
      sort: 'asc',
    },
    {
      label: 'Quantity',
      field: 'order_status',
      sort: 'asc',
    },
    {
      label: 'Payment Type',
      field: 'payment_type',
      sort: 'asc',
    },
    {
      label: 'View More',
      field: 'view_more',
    },
  ];

  // Define rows for the table
  const rows = returnList.map((item, i) => ({
    order_id: item.order_id || 'NA',
    name: item.name || 'NA',
    price: item.price || 0,
    order_status: item.order_status || 0,
    payment_type: item.payment_type || 'NA',
    view_more: (
      <div className="dropdown">
        <Link className="dropdown-item" to="#">
          View Full Details
        </Link>
      </div>
    ),
  }));

  return (
    <>
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="card">
            <h5 className="card-header">Return Table bla lb</h5>
            
            <div className="table-responsive text-nowrap">
              <MDBDataTable
                striped
                bordered
                hover
                data={{ columns, rows }}
                responsive
                searching={true}
                paging={true}
                noBottomColumns={true}
              />
            </div>
          </div>
        </div>
        <div className="content-backdrop fade"></div>
      </div>
    </div>
    </>
  );
}
