import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { getCookie } from 'cookies-next';
import secrets from '../constant/secrets';
import useSWR from 'swr';
// import { API_URL } from '../network/endpoints';
import { fetchWithToken } from '../network';
import DashboardLayout from '../layouts';
import { Box, Button, Card, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../network/endpoints';

export default function Inventory() {
    const [update,setupdate]=useState({})
    const [price,setprice] = useState('');
	const [stoke, setstoke] = useState('');

	const handleChangeprice = (e) => {
		setprice(e.target.value);
	};
	const handleChangestoke = (e) => {
		setstoke(e.target.value);
	};
    
    const token = getCookie(secrets.DISTRIBUTOR_ID);
    const url = `https://api.meddaily.in/distributor_get_product`;
    const { data, isLoading, error } = useSWR(
        token ? [url, token] : null,
        ([url, token]) =>
            fetchWithToken(url, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    token,
                },
            })
    );
    
    const UpdateInventory=()=> {
        const newdata=localStorage.getItem("getdetails");
        const Disdata=JSON.parse(newdata)
       
        console.log("Data stored in localStorage:", Disdata);
        const payload = {
            productId: data?.product[0]?._id, 
            distributorId: Disdata?.data?._id,
            distributorName: Disdata?.data?.firstname,
            price: parseInt(price),
            stock: parseInt(stoke), 
          };
          axios.post(
            `${API_URL}/update_inventory`,
            payload,
            {
              headers: {
                token: token,
              },
            }
          )
          .then((res) => {
              if (res.status === 200) {
                  setupdate(res.data.data);
                  console.log(">>>>>>",update);
            }
          })
          .catch((err) => {
            // toastr.error(err.response.data.message);
            console.log(err);
        });
    };
        useEffect(() => {
            UpdateInventory();
          }, []);
    return (
        <>
            <DashboardLayout>


                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h5
                        style={{
                            padding: '1rem',
                        }}
                    >
                        Inventory Update
                    </h5>
                    <div className="table-responsive text-nowrap">
                        {isLoading ? (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderTop: '1px solid #ccc',
                                        borderRadius: '5px',
                                        height: '200px',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            </Box>
                        ) : (
                            <table >
                                <thead>
                                    <tr>
                                        <td style={{ padding: '1rem' }}>Name</td>
                                        <td style={{ padding: '1rem' }}>{data?.product[0]?.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '1rem' }}>Mfn Name</td>
                                        <td style={{ padding: '1rem' }}>{data?.product[0]?.sub_title}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '1rem' }}>Description</td>
                                        <td style={{ padding: '1rem' }}>{data?.product[0]?.description}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '1rem' }}>Price</td>

                                        <td style={{ padding: '1rem' }}>
                                            <input 
                                            type='text'
                                            value={update?.price}
                                            onChange={handleChangeprice}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '1rem' }}>stock</td>

                                        <td style={{ padding: '1rem' }}>
                                            <input 
                                            type='text'
                                            value={update?.stock}
                                            onChange={handleChangestoke}></input>
                                        </td>
                                    </tr>
                                    <Button 
                                    style={{padding:"10px 40px",margin:"10px"}}
                                    className='btn' variant="primary" size="sm"
                                    onClick={UpdateInventory}>
                                        Add
                                    </Button>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {/* {data?.product &&
                                        data?.product.length > 0 &&
                                        data?.product.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ padding: '1rem' }}>{item?.name}</td>
                                                  
                                                </tr>
                                            );
                                        })} */}
                                    {/* {error && (
                    <tr>
                      <td colSpan="4">Something went wrong</td>
                    </tr>
                  )} */}
                                    {/* {data?.product && data?.product.length <= 0 && (
                    <tr>
                      <td colSpan="4">No Data</td>
                    </tr>
                  )} */}
                                </tbody>
                            </table>
                        )}
                    </div>
                </Card>
            </DashboardLayout>
        </>
    );
};

