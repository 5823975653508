import { Margin, Padding } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Buffer } from "buffer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toastr from "toastr";
import { API_URL } from "../network/endpoints";
import { Box, createTheme } from "@mui/material";
const token = localStorage.getItem("disToken");

const orderStatuses = {
  1: "Order shipped",
  3: "Order delivered",
  4: "Order placed",
  0: "Order cancelled",
};
function OrderDetails({ handleClose, order_id }) {
  const [details, setDetails] = useState(null);
  const [createInvoice, setCreateInvoice] = useState(false);
  // const [invoiceCreated, setInvoiceCreated] = useState(false);
  // const [formState, setFormState] = useState({
  //   batchNo: "",
  //   expDate: "",
  // });
  // const [formState, setFormState] = useState({
  //   products: details?.products?.map((product) => ({
  //     batchNo: "",
  //     expDate: "",
  //   })) || [],
  // });
  const [formState, setFormState] = useState({
    products: (details?.products || []).map(() => ({
      batchNo: "",
      expDate: "",
    })),
  });
  const [retailerData, setRetailerData] = useState(null);
  const [distributorData, setDistributorData] = useState(null);
  console.log(order_id);

  useEffect(() => {
    const fetchData = async () => {
      console.log(order_id);
      const config2 = {
        headers: {
          token,
        },
      };
      try {
        const response = await axios.get(
          `${API_URL}/order_details?order_id=${order_id}`,
          config2
        );

        console.log(response?.data);
        if (response.status === 200) {
          // console.log("NEW DATA", response.data.status.data);
          setDetails(response?.data?.data);

          const retailerId = response?.data?.retailer_id;
          const distributorId = response?.data?.distributor_id;

          if (retailerId) {
            const retailerResponse = await axios.post(
              `${API_URL}/retailer_detail`,
              { id: retailerId }
            );

            if (retailerResponse.status === 200) {
              setRetailerData(retailerResponse?.data?.data);
            }
          }

          if (distributorId) {
            const distributorResponse = await axios.post(
              `${API_URL}/distributor_detail`,
              { id: distributorId }
            );

            if (distributorResponse.status === 200) {
              setDistributorData(distributorResponse?.data?.data);
            }
          }
        }
      } catch (err) {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    };
    console.log("ITEM", details?.order_status);

    fetchData();
  }, [order_id]);
  const sendAndShip = async (index) => {
    // Check if at least one product has a valid batch number and expiration date
    // const validProduct = formState.products.some(product => product.batchNo && product.expDate);

    if (details?.products?.length > 0) {
      // console.log("formState.expDate:", formState.products[0]?.expDate);
      // console.log("formState.batchNo:", formState.products[0]?.batchNo);
      // console.log("details?.products?.length:", details?.products?.length);
      // formState.products.forEach((product, index) => {
      //   console.log(`Product ${index + 1} - ExpDate:`, product.expDate);
      //   console.log(`Product ${index + 1} - BatchNo:`, product.batchNo);
      // });
      try {
        const items = details.products.map((product, i) => ({
          _id: product.id,
          batch_no: formState.products[i].batchNo,
          exp_date: formState.products[i].expDate,
          quantity: product.quantity,
        }));
        console.log("items", items);
        console.log("Product", details.products);
        const response = await axios.post(
          `${API_URL}/create_invoice`,
          {
            items: items,
            order_id: details?.order_id,
          },
          {
            headers: {
              token: token,
            },
          }
        );

        if (response.status === 200) {
          toastr.success("Invoice sent");
          console.log("Invoice sent successfully");
          handleClose(true);
        }
      } catch (err) {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    } else {
      toastr.error(
        "Batch No., Exp Date, and at least one Product ID are required."
      );
    }
  };

  // const sendAndShip = async () => {
  //   console.log("formState.expDate:", formState.expDate);
  //   console.log("formState.batchNo:", formState.batchNo);
  //   console.log("details?.products?.length:", details?.products?.length);
  //   if (formState.expDate && formState.batchNo && details?.products?.length > 0) {
  //     try {
  //       const items = details.products.map(product => ({
  //         _id: product.id,
  //         batch_no: formState.batchNo,
  //         exp_date: formState.expDate,
  //         quantity: product.quantity
  //       }));

  //       const response = await axios.post(
  //         `${API_URL}/create_invoice`,
  //         {
  //           items: items,
  //           order_id: details?.order_id,
  //         },
  //         {
  //           headers: {
  //             token: token,
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         toastr.success("Invoice sent");
  //         console.log("Invoice sent successfully");
  //         handleClose(true);
  //       }
  //     } catch (err) {
  //       toastr.error(err?.response?.data?.message);
  //       console.log(err);
  //     }
  //   } else {
  //     toastr.error("Batch No., Exp Date, and at least one Product ID are required.");
  //   }
  // };

  const handleCancelOrder = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/cancel_order_retailer?order_id=${details?.order_id}`
      );
      if (response.data.status === true) {
        // console.log(response.data);
        alert("Order Cancelled Successfully");
        handleClose(true);
      } else {
        console.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const handleDownloadCreditSummary = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/get_credit_summary?order_id=${details?.order_id}`
      );
      if (response?.status === 200) {
        const pdfBase64 = response?.data?.data;
        const binaryData = atob(pdfBase64);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const view = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          view[i] = binaryData.charCodeAt(i);
        }
        // Create Blob from ArrayBuffer
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        // Create URL for the Blob
        const url = URL.createObjectURL(blob);
        // Create a link and click it to trigger download
        const link = document.createElement("a");
        link.href = url;
        link.download = `credit_summary_${details?.order_id}.pdf`; // You can customize the filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching credit summary:", error);
    }
  };
  return (
    <Modal
      show
      onHide={handleClose}
      centered
      size="lg"
      className="mt-5"
      style={
        {
          // height: "12rem",
        }
      }
    >
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="flex-grow-1">
            <div className="row">
              <div className="">
                <div className="card-body">
                  <div>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <p>
                          <strong>Order ID:</strong> {details?.order_id}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Order Date:</strong>{" "}
                          {new Date(details?.createdAt).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Order Status:</strong> {details?.order_status}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Payment Type:</strong>{" "}
                          {details?.payment_type === 1
                            ? "Cash on delivery"
                            : ""}
                          {details?.payment_type}
                          {details?.payment_type === 3 ? "Creadit" : ""}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Total Price:</strong>{" "}
                          {(
                            details?.price -
                            (details?.delivery_fee === true ? 118 : 0)
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Payment Status:</strong>{" "}
                          {details?.payment_status}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Retailer Name:</strong>{" "}
                          {details?.retailer_name}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Distributor Name:</strong>{" "}
                          {details?.distributor_name}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Retailer-Address:</strong>{" "}
                          {/* {console.log(
                            "dom perigon",
                            details?.payment_status
                          )} */}
                          {details?.retailer_address}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <strong>Distributor-Address:</strong>{" "}
                          {details?.distributor_address}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <h4>Products:</h4>
                      {details &&
                        details?.products &&
                        details?.products.length > 0 &&
                        details?.products.map((product, index) => (
                          <div className="mt-5 pt-5" key={index}>
                            <div
                              className="w-100 card "
                              style={{ marginLeft: 0 }}
                            >
                              <div className="card-body">
                                <h5 className="card-text">
                                  Product Name: {product.name}
                                </h5>
                                <p className="card-text">
                                  Price: {product.price}
                                </p>
                                <p className="card-text">
                                  Quantity: {product.quantity}
                                </p>
                                <p className="card-text">
                                  Total Price:{" "}
                                  {product.price * product.quantity}
                                </p>

                                {(product.batch_no && product.exp_date) ||
                                details.order_status === 0 ? (
                                  <div>
                                    <label
                                      htmlFor="batch"
                                      className="form-label float-start"
                                    >
                                      Batch no.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="batch"
                                      name="batch"
                                      placeholder="Batch no."
                                      value={product.batch_no}
                                      autoFocus
                                    />

                                    <label
                                      htmlFor="exp"
                                      className="form-label float-start"
                                    >
                                      Exp Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="batch"
                                      name="batch"
                                      placeholder="Exp date"
                                      value={product.exp_date}
                                      autoFocus
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    {/* <label htmlFor="batch" className="form-label float-start">
                                      Batch no.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="batch"
                                      name="batch"
                                      placeholder="Batch no."
                                      // value={formState.batchNo}
                                      onChange={({ target }) => {
                                        setFormState({
                                          ...formState,
                                          batchNo: target.value,
                                        });
                                      }}
                                      autoFocus
                                    />

                                    <label htmlFor="exp" className="form-label float-start">
                                      Exp Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="batch"
                                      name="batch"
                                      placeholder="Exp date"
                                      // value={formState.expDate}
                                      onChange={({ target }) => {
                                        setFormState({
                                          ...formState,
                                          expDate: target.value,
                                        });
                                      }}
                                      autoFocus
                                    /> */}
                                    <label
                                      htmlFor={`batch-${index}`}
                                      className="form-label float-start"
                                    >
                                      Batch no.
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`batch-${index}`}
                                      name={`batch-${index}`}
                                      placeholder="Batch no."
                                      value={
                                        formState.products[index]?.batchNo || ""
                                      }
                                      onChange={({ target }) => {
                                        const updatedProducts = [
                                          ...formState.products,
                                        ];
                                        updatedProducts[index] = {
                                          ...updatedProducts[index],
                                          batchNo: target.value,
                                        };
                                        setFormState({
                                          ...formState,
                                          products: updatedProducts,
                                        });
                                      }}
                                      autoFocus
                                    />

                                    <label
                                      htmlFor={`exp-${index}`}
                                      className="form-label float-start"
                                    >
                                      Exp Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id={`exp-${index}`}
                                      name={`exp-${index}`}
                                      placeholder="Exp date"
                                      value={
                                        formState.products[index]?.expDate || ""
                                      }
                                      onChange={({ target }) => {
                                        const updatedProducts = [
                                          ...formState.products,
                                        ];
                                        updatedProducts[index] = {
                                          ...updatedProducts[index],
                                          expDate: target.value,
                                        };
                                        setFormState({
                                          ...formState,
                                          products: updatedProducts,
                                        });
                                      }}
                                      autoFocus
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {details?.order_status === 3 && details?.payment_type === 2 ? (
          <button
            variant="text"
            onClick={handleDownloadCreditSummary}
            className="btn"
            style={{
              backgroundColor: "#DC143C",
              fontWeight: "700",
              color: "white",
              borderRadius: "8px",
            }}
          >
            Download Credit Note
          </button>
        ) : (
          ""
        )}

        {details?.order_status !== 0 &&
        details?.order_status !== 1 &&
        details?.order_status !== 3 ? (
          <button
            variant="text"
            onClick={handleCancelOrder}
            className="btn"
            style={{
              backgroundColor: "#DC143C",
              fontWeight: "700",
              color: "white",
              borderRadius: "8px",
            }}
          >
            Cancel Order
          </button>
        ) : (
          ""
        )}

        {details?.order_status !== 1 &&
        details?.order_status !== 3 &&
        details?.order_status !== 0 ? (
          <button
            variant="text"
            onClick={() => sendAndShip()}
            className="btn"
            style={{
              backgroundColor: "#6EAFAB",
              fontWeight: "700",
              color: "white",
              borderRadius: "8px",
            }}
          >
            Create Invoice
          </button>
        ) : (
          ""
        )}
        {/* {!createInvoice ? (
          <Button variant="primary" onClick={() => sendAndShip()}>
            Create Invoice
          </Button>
        ) : (
          createInvoice && (
            <Button variant="primary" onClick={() => sendAndShip()}>
              Create Invoice
            </Button>
          )
        )} */}
      </Modal.Footer>
    </Modal>
  );
}

export default OrderDetails;
