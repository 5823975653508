import React, { useEffect, useState } from "react";
import axios from "axios";
import toastr from "toastr";
// import { Link } from 'react-router-dom';
// import Navbar from './Navbar';
// import Sidebar from './Sidebar';
// import config from '../appConfig';
// import Sidebar2 from '../DistributorLogin/sidebar2';
import Dropdown from "react-bootstrap/Dropdown";
import { Card } from "@mui/material";
import { Modal } from "react-bootstrap";
import { API_URL } from "../network/endpoints";
import DashboardLayout from "../layouts";
import { Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact"; // Import MDBDataTable

export default function Payouttable() {
  const authToken = localStorage.getItem("authToken");
  const token = localStorage.getItem("disToken");
  // const navigate = useNavigate();
  const [payoutList, setPayoutList] = useState([]);
  const [payout, setPayout] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [amountInput, setAmountInput] = useState("");
  const [filter, setFilter] = useState("All");
  const [validationMessage, setValidationMessage] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value <= payout) {
      setValidationMessage("");
      setIsButtonVisible(true);
    } else {
      setValidationMessage(
        `Please enter an amount equal to or less than ${payout}/-`
      );
      setIsButtonVisible(false);
    }
    setAmountInput(value);
  };
  // const handleAmountChange = (event) => {
  //   setAmountInput(event.target.value);
  // };

  const handleModalClose = () => {
    setShowModal(false);
    setAmountInput("");
  };

  useEffect(() => {
    getAllPayout();
    getPayout();
  }, [authToken, filter]);

  async function getAllPayout() {
    const config = {
      headers: {
        token: token,
      },
    };
    await axios
      .get(`${API_URL}/all_payout_request_distributor/${filter}`, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data);
          setPayoutList(res.data.data);
        }
      })
      .catch((err) => {
        toastr.error(err.response?.data?.message);
        console.log(err);
      });
  }

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  async function getPayout() {
    const config = {
      headers: {
        token: token,
      },
    };
    await axios
      .get(`${API_URL}/get_payout_amount`, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setPayout(res?.data?.data?.amount);
        }
      })
      .catch((err) => {
        toastr.error(err.response?.data?.message);
        console.log(err);
      });
  }

  const createpayout = async () => {
    const payload = {
      amount: parseInt(amountInput),
    };
    const config = {
      headers: {
        token: token,
      },
    };
    await axios
      .post(`${API_URL}/create_payout`, payload, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data);
          handleModalClose();
          window.location.reload();
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        console.log(err);
      });
  };

  // Define columns for the MDBDataTable
  const columns = [
    {
      label: "Request ID",
      field: "_id",
    },
    {
      label: "Vendor name",
      field: "distributor_id.firstname",
    },
    {
      label: "Amount",
      field: "amount",
    },
    {
      label: "Created",
      field: "createdAt",
    },
  ];

  // Prepare rows data for the MDBDataTable
  const rows = payoutList.map((item) => ({
    _id: (
      <i className="fab fa-angular fa-lg  me-3">
        <strong>{item._id}</strong>
      </i>
    ),
    "distributor_id.firstname": item.distributor_id.firstname,
    amount: item.amount,
    createdAt: new Date(item.createdAt).toLocaleDateString("en-GB"),
  }));

  // Data for MDBDataTable
  const dataTableData = {
    columns,
    rows,
  };

  return (
    <DashboardLayout>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 className="card-header">Payout Request</h5>
          <div style={{ display: "flex" }}>
            <Dropdown>
              <Dropdown.Toggle
                className="btn"
                variant="text"
                style={{
                  backgroundColor: "#6EAFAB",
                  color: "white",
                  margin: "17px 0px ",
                  borderRadius: "8px",
                }}
              >
                Filter By
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleFilterChange("All")}>
                  All
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange("1")}>
                  Pending Request
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange("2")}>
                  Complete Request
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Dropdown>
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  style={{ backgroundColor: "#6EAFAB", color: "white", margin: "17px 0px " }}
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  Filter By
                </button>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleFilterChange('All')}>All</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilterChange('1')}>Pending Request</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleFilterChange('2')}>Complete Request</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            {/* <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => handleFilterChange('All')}
                          >
                            All
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => handleFilterChange('1')}
                          >
                            pending request
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => handleFilterChange('2')}
                          >
                            complete request
                          </Link>
                        </li>
                      </ul> */}
            {/* </div> */}
            <Button
              variant="text"
              sx={{
                fontSize: "12px",
              }}
              style={{
                backgroundColor: "#6EAFAB",
                color: "white",
                margin: "17px 10px ",
                borderRadius: "8px",
              }}
              onClick={() => setShowModal(true)}
            >
              Create Payout
            </Button>
            <Modal
              show={showModal}
              onHide={handleModalClose}
              style={{ margin: "50px 150px" }}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "136px",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "343px",
                    fontWeight: "500",
                    fontSize: "22px",
                    backgroundImage:
                      "linear-gradient(to bottom, #960018, #ab0720, #c00f28, #d51730, #eb1e39)",
                    color: "white",
                    borderRadius: "10px",
                    padding: "10px 0px 20px 10px",
                  }}
                >
                  <p style={{ display: "flex", fontSize: "16px" }}>
                    Net receivable
                  </p>
                  {payout}/-
                </div>
              </div>

              <div
                style={{
                  textAlign: "left",
                  margin: "25px 100px",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                PayOut Request
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                    padding: "0px",
                  }}
                >
                  <p htmlFor="amountInput">Enter Amount</p>
                </div>
                <input
                  type="number"
                  id="amountInput"
                  style={{
                    borderRadius: "14px",
                    width: "364px",
                    height: "58px",
                  }}
                  value={amountInput}
                  placeholder="  Amount"
                  onChange={handleAmountChange}
                />
                {validationMessage && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {validationMessage}
                  </div>
                )}
              </div>
              {isButtonVisible && (
                <div style={{ textAlign: "center", paddingBottom: "15px" }}>
                  <button
                    style={{
                      backgroundColor: "#6EAFAB",
                      padding: "10px 40px",
                      color: "white",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    onClick={createpayout}
                  >
                    Create Payout
                  </button>
                </div>
              )}
            </Modal>
            {/* <p style={{ marginTop: "30px" }}>Total price:{payout}</p> */}
          </div>
        </div>
        <div
          className="table-responsive text-nowrap"
          style={{ textAlign: "center" }}
        >
          <MDBDataTable
            striped
            bordered
            small
            data={dataTableData}
            noBottomColumns={true}
          />
        </div>
      </Card>
    </DashboardLayout>
  );
}
