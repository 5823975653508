import React from 'react';
import toastr from 'toastr';
// import Sidebar from './Sidebar';
// import Navbar from './Navbar';
// import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../network/endpoints';

const apiUrl = `${API_URL}/update_inventory`;
// const apiUrl = 'http://localhost:8000/update_inventory';


const UpdateInventory = (props) => {
  // const newid=localStorage.getItem('newid')
  // console.log("<<<<<<<<props>>>>>>>>>>>>>>>>>>>>>>>>",props);
  localStorage.setItem('props',props.id)
  const navigate = useNavigate();
  // const [activeMenu, setactiveMenu] = useState('dashbord');
  // const [productId, setproductId] = useState('');
  // const [productName, setproductName] = useState('dashbord');
  // const [data,setdata]=useState('')

  // console.log('props', props.id);

  // const sideMenuClick = (selected, id = null, name = null) => {
  //   setproductName(name);
  //   setproductId(id);
  //   console.log(id, 'id', name, 'name', selected, 'selected');
  //   window.history.pushState(null, '', selected);
  //   setactiveMenu(selected);
  // };
  // const [edit, setEdit] = useState('');
  const [inventory, setInventory] = useState({
    name: '',
    price: '',
    stock: '',
  });

  // const location = useLocation();
  // const { productId,productName } = location.state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/my_inventory`,
          { headers: { token: localStorage.getItem("disToken") } });
        // const fetchedData = response?.data?.product;
        // // setEdit(fetchedData);
        // setInventory({
        //   ...inventory,
        //   price: fetchedData.price || '',
        //   stock: fetchedData.stock || '',
        // });
        const foundProduct = response.data.product.filter((item) => item._id===(props.id));

        // console.log("DTAS",foundProduct);
        // setdata(foundProduct)
        // If the product is found, update the inventory state
        if (foundProduct.length > 0) {
          setInventory({
            ...inventory,
            price: foundProduct[0].price || '',
            stock: foundProduct[0].stock || '',
          })}
        // console.log("Fetched Data", fetchedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  // const { title, _id } = edit;
  const handle = (e) => {
    const { name, value } = e.target;
    setInventory({ ...inventory, [name]: value });
  };

  const {  price, stock } = inventory;
  // console.log("STOKEPRICW", inventory);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const distributor = JSON.parse(localStorage.getItem('distributor_details'));
    // console.log(distributor.data.firstname, 'name');
    //     console.log(distributor,'distributor');
    //     let update_inventory = {
    //       "productId":props.disId,
    //       "distributorId": distributor.data._id,
    //       "distributorName":name,
    //       "price": price,
    //       "stock": stock
    //  }

    const disToken = JSON.parse(String(localStorage.getItem('distributor_details')));
    // const disId = localStorage.getItem('id');

    if (price === '') {
      return toastr.warning('price cannot be empty !');
    }
    if (stock === '') {
      return toastr.warning('stock cannot be empty !');
    }
    // var activeRoutes = window.location.pathname.split('/');
    // var id = activeRoutes[activeRoutes.length - 1];

    const reqBody = {
      productId: props.id,
      distributorId: distributor.data._id,
      distributorName: distributor.data.firstname,
      price: price,
      stock: stock,
      // state: distributor.data.state
    };
    console.log('reqBody', reqBody);
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        token: disToken?.token,
      },
    };
    try {
      const response = await axios.post(apiUrl, reqBody, axiosConfig);
      if (response.status === 200) {
        toastr.success(response.data.message);
        setInventory({
          name: '',
          price: '',
          stock: '',
        });
      }
      navigate('/my-inventory');
     

      //   console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setInventory({
      name: '',
      price: '',
      stock: '',
    });
    navigate('/my-inventory');

  };

  return (
    <>
     
      <div className="mt-3">
        <form id="formAccountSettings">
          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="form-label float-start" for="price " style={{color:"#6EAFAB",fontWeight:"700"}}>
               Update Price
              </label>
              <div className="input-group input-group-merge">
                <input
                  type="number"
                  id="price"
                  name="price"
                  className="form-control"
                  placeholder="100"
                  value={inventory.price}
                  onChange={handle}
                />
              </div>
            </div>
            <div className="mb-3 col-md-6">
              <label for="stock" className="form-label float-start"  style={{color:"#6EAFAB",fontWeight:"700"}}>
               Upadate Stock
              </label>
              <input
                className="form-control"
                type="number"
                id="stock"
                name="stock"
                placeholder="20"
                value={inventory.stock}
                onChange={handle}
              />
            </div>
          </div>
          <button className="btn" style={{backgroundColor:"#6EAFAB",color:"white",width:"20%",borderRadius:"10px"}} onClick={handleUpdate}>
            Update
          </button>
          <button className="btn  mx-3"style={{backgroundColor:"#DC143C",color:"white",width:"20%",borderRadius:"10px"}} onClick={handleCancel}>
            Cancel
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateInventory;