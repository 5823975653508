import React, { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';
import { setCookie } from 'cookies-next';
import secrets from '../constant/secrets';
import { Box, Button, Card } from '@mui/material';
import { API_URL } from '../network/endpoints';
export default function Login() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    password: '',
    showPass: false,
  });

  const onChange = React.useCallback(
    (e) => {
      setState((state) => ({ ...state, [e.target.name]: e.target.value }));
    },
    [setState]
  );

  const togglePassword = React.useCallback(
    (e) => {
      setState((state) => ({ ...state, showPass: !state.showPass }));
    },
    [setState]
  );

  const login = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (state.email === '') {
        return toastr.warning('Please enter your email');
      }
      if (state.password === '') {
        return toastr.warning('Please enter your state.');
      }
      if (!state.email && !state.password) {
        return toastr.warning('Please enter your email and password');
      }
      const payload = {
        phone: state.email,
        password: state.password,
      };

      // Async await
      const res = await axios.post(
        `${API_URL}/distributor_login`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (res.data.status === true) {
        setCookie(secrets.DISTRIBUTOR_ID, res?.data?.token);
        localStorage.setItem('disToken', res?.data?.token);

        localStorage.setItem('distributor_details', JSON.stringify(res?.data));
        // console.log();
        navigate('/distributor/dashboard');
      } else {
        toastr.error(res?.data?.message);
      }
    },
    [state, navigate]
  );

  return (
    <>
      {/* style={{ width: "45rem" }} */}
      {/* <div
        className="container-xxl disLogin"
        columns={{ xs: 4, md: 12, lg: 8 }}
      >
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="loginCss">
              <div className="card-body ">
                <div
                  className="app-brand justify-content-center"
                  style={{ padding: '1rem .5rem' }}
                >
                  <Link to="/" className="app-brand-link gap-2">
                    <img
                      src="../assets/img/logo2.png"
                      alt="user"
                      style={{
                        height: '120px',
                        width: '300px',
                        objectFit: 'contain',
                      }}
                    />
                  </Link>
                </div>
                <div id="formAuthentication" className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label float-start">
                      Email or Username /DISTRIBUTOR LOGIN
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter your email or username"
                      onChange={handleChangeEmail}
                      autoFocus
                    />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type={showPass ? 'text' : 'password'}
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        onChange={handleChangePassword}
                        aria-describedby="password"
                      />
                      <span className="input-group-text cursor-pointer">
                        <i
                          className={showPass ? 'bx bx-show' : 'bx bx-hide'}
                          onClick={togglePassword}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label className="form-check-label" htmlFor="remember-me">
                        {' '}
                        Remember Me{' '}
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn d-grid w-100"
                      type="button"
                      onClick={login}
                      style={{ background: '#6eafab', color: 'white' }}
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // height: '90%',
            width: 600,
            p: 2,
          }}
        >
          <div
            className="app-brand justify-content-center"
            style={{ padding: '1rem .5rem' }}
          >
            <img
              src="../assets/img/logo2.png"
              alt="user"
              style={{
                height: '120px',
                width: '300px',
                objectFit: 'contain',
              }}
            />
            {/* <Link to="/" className="app-brand-link gap-2">
            </Link> */}
          </div>
          <form id="formAuthentication" className="mb-3" onSubmit={login}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label float-start">
                Email or Username/DISTRIBUTOR LOGIN
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter your email or username"
                onChange={onChange}
                autoFocus
              />
            </div>
            <div className="mb-3 form-password-toggle">
              <div className="d-flex justify-content-between">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="input-group input-group-merge">
                <input
                  type={state.showPass ? 'text' : 'password'}
                  id="password"
                  className="form-control"
                  name="password"
                  placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                  onChange={onChange}
                  aria-describedby="password"
                />
                <span className="input-group-text cursor-pointer">
                  <i
                    className={state.showPass ? 'bx bx-show' : 'bx bx-hide'}
                    onClick={togglePassword}
                  ></i>
                </span>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="remember-me"
                />
                <label className="form-check-label" htmlFor="remember-me">
                  {' '}
                  Remember Me{' '}
                </label>
              </div>
            </div>
            <div className="">
              {/* <button
                className="btn d-grid w-100"
                type="button"
                onClick={login}
                style={{ background: '#6eafab', color: 'white' }}
              >
                Sign in
              </button> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: '#6eafab', color: 'white' }}
              >
                Sign in
              </Button>
            </div>
          </form>
        </Card>
      </Box>
    </>
  );
}
// axios
//   .post(`${API_URL}/distributor_login`, reqBody, axiosConfig)
//   .then((res) => {
//     if (res.data.status === true) {
//       setCookie(secrets.DISTRIBUTOR_ID, res?.data?.token);
//       localStorage.setItem(
//         'distributor_details',
//         JSON.stringify(res?.data)
//       );
//       navigate('/distributor/dashboard');
//     } else {
//       toastr.error(res?.data?.message);
//     }
//   })
//   .catch((err) => {
//     toastr.error(err?.response?.data?.message);
//     console.log(err);
//   });
