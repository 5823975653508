import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: '#fff',
        gap: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
          gap: '1rem',
          border: '1px solid #eee',
          p: 4,
          borderRadius: '1rem',
        }}
      >
        <Typography variant="h4">404 Page Not Found</Typography>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          <Typography
            variant="h6"
            sx={{ color: '#444', textTransform: 'capitalize' }}
          >
            Go Back
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
export default NotFound;
